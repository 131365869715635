.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff
}
body {
  background-color: #3f51b5;
  margin: 0;
}
h1 {
  font-size: 48px;
  margin: 0;
}
p {
  margin: 0;
}

.img-under-development {
  width: 100%;
  max-width: 600px;
  margin: 24px 0;
}
.logo-title {
  display: flex;
  justify-content: center;
  line-height: 50px;
  margin-bottom: 24px;
}
.logo {
  width: 48px;
  height: 48px;
  max-width: 48px !important;
}
.logo-title span {
  font-size: 48px;
  margin-left: 8px;
  align-self: center;
  font-weight: bold;
}
.dg-subtitle {
  font-size: 18px;
  margin: 8px 0;
}

.btn-home {
  margin: 24px 0;
}

.waiting-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 48px 0;
}
.waiting-list-input {
  margin: 8px 0;
}

.waiting-list-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px;
  button {
    margin-left: 8px;
    max-width: 80px;
  }
}
.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
}