.register-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.register-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px;
    background-color: #fff;
}
.close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.register-button {
    margin: 8px 0 !important;
}

.success {
    color: green;
}

.error {
    color: red;
}